// Privacy.jsx
import React from 'react';
import HeaderMenuLeft from '../components/HeaderMenuLeft.jsx';

// Constants for easy updates
const PRODUCT_NAME = "Quickmap Flowchart Creator";
const COMPANY_NAME = "Business Daddy Studio, Inc.";
const WEBSITE_URL = "https://quickmap.cc";
const CONTACT_EMAIL = "support@businessdaddy.org";
const CURRENT_YEAR = new Date().getFullYear();

const Privacy = () => {
  return (
    <>
      <HeaderMenuLeft />
      <div className="bg-gray-50 min-h-screen py-16 px-6">
        <div className="max-w-4xl mx-auto text-gray-900">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          <p className="mb-4">
            Your privacy is important to us. This privacy policy explains the types of personal information we collect and how we use, share, and protect that information.
          </p>
          
          <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>
          <p className="mb-4">
            The {PRODUCT_NAME} Chrome extension does not collect, track, or share any personal information. It operates entirely locally on your browser, and no data is sent to external servers.
          </p>
          <p className="mb-4">
            On our website ({WEBSITE_URL}), we use analytics tools to collect non-identifying information about how visitors interact with our site. This includes data like page views, browser type, and time spent on the site. This information helps us improve our website and services.
          </p>
          
          <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Information</h2>
          <p className="mb-4">
            For the {PRODUCT_NAME} Chrome extension, no user data is collected, stored, or used. Any flowchart data remains on your local device.
          </p>
          <p className="mb-4">
            On our website, the analytics data we collect is used to understand user behavior and improve the site's performance and usability. No personally identifiable information is collected through analytics.
          </p>
          
          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Sharing and Protection</h2>
          <p className="mb-4">
            {COMPANY_NAME} respects your privacy and will not sell or share your data with third parties without consent, except as required by law. Analytics data collected on our website is aggregated and anonymized before analysis.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Choices</h2>
          <p className="mb-4">
            You have choices regarding your information. On our website, you can opt out of analytics tracking by disabling cookies in your browser settings or using a browser extension that blocks analytics scripts. For more information, contact us at <a href={`mailto:${CONTACT_EMAIL}`} className="text-indigo-600 hover:underline">{CONTACT_EMAIL}</a>.
          </p>
          
          <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this privacy policy, please contact us at <a href={`mailto:${CONTACT_EMAIL}`} className="text-indigo-600 hover:underline">{CONTACT_EMAIL}</a>.
          </p>
          
          <p className="mt-8 text-sm text-gray-600">
            &copy; {CURRENT_YEAR} <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">{COMPANY_NAME}</a>. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;